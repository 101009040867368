import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/autoplay'

const SwiperModules = [Navigation, Pagination, Scrollbar, A11y, Autoplay]

const SwiperPlugin = {
  install(app, options) {
    app.config.globalProperties.$swiperModules = SwiperModules
    app.component('swiper', Swiper)
    app.component('swiper-slide', SwiperSlide)
  }
}

export default SwiperPlugin
